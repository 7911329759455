body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Overpass", sans-serif;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border-radius: 6px;
}
input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: rgb(22, 180, 224);
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: #0052cc;
  cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}
