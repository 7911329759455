.EditPage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  width: 800px;
}

.EditProfilePage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  flex-grow: 1;
  background-color: black;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

.emoji-picker-react {
  height: 400px !important;
  width: 100% !important;
}

.edit-form {
  width: 90%;
}

.form-header {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

