.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
  font-weight: 700;
}
