@import url(https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #dadce1;
  color: var(--text-color);
  text-decoration: none;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.dark-text {
  color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SignupPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container {
  width: 29%;
}

.EditPage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  width: 800px;
}

.EditProfilePage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  flex-grow: 1;
  background-color: black;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

.emoji-picker-react {
  height: 400px !important;
  width: 100% !important;
}

.edit-form {
  width: 90%;
}

.form-header {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}


.header {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

.header {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}

.nav-item {
  /* width: calc(var(--nav-size) * 0.8); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link-right {
  padding-right: 20px;
}

.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav-j {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.icon-button svg { 
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}


/* Dropdown Menu */

.dropdown {
  position: absolute;
  z-index: 9990;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  min-height: 134px;
}

.header-username {
  color: white
}

.left-nav a {
  padding-right: 20px
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
  -webkit-filter: none;
          filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

/* .menu-secondary-exit {

} */

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
  font-weight: 700;
}

.container {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.feed {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  flex-grow: 1;
  background-color: black;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

body {
  font-family: 'Montserrat', 'Roboto', sans-serif;
}

.container .post-card {
  background-color: white;
  width: 700px;
  height: auto;
  box-shadow: 0 1px 5px rgba(0,0,0,0.5);  
  display: flex;
  border-radius: 0.3rem;
  margin: 10px;
}

.container .post-card:first-child {
  margin-top: 0;
}

.post-card__content {
  display: flex;
  position: relative;
  width: calc(100% - 70px);
  height: auto;
  padding: 25px;
}

.post-card__info h1 {
  display: block;
  margin: 0;
  font-size: 1.1em;
  /* font-weight: 100; */
  color: #333;
}

.post-card__info h5 {
  display: block;
  margin-top: 0.7rem;
  font-weight: 100;
}

.post-card__info h5 span {
  color: #333;
  font-weight:300;
}

.post-card__info p {
  margin-top: 1rem;
  color: #333;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3rem;
  text-align: left;
}

.post-card__info a {
  color: #f44242;
  text-decoration: none;
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.7em;
}

.post-card__info a:hover {
  text-decoration: underline;
}

.post-card__img {
  width: 40%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  transition: all 1s;
}

.post-card__img .post-card__social ul {
  list-style-type: none;
  margin: 0;
  padding: 1rem 2rem;
  width: 100%;
  text-align: right;
  color: white;
}

.img-banner {
  height: 206px;
}

.post-card__img .post-card__social ul li {
  display: inline-block;
  text-shadow: 0 1px rgba(0,0,0,0.5);
}

.post-card__img .post-card__social ul li:first-child {
  margin-right: 2rem; 
}

.post-card__img .post-card__social ul li i {
  margin-right: 0.6rem;
  transition: 0.2s all;
}

.post-card__img .post-card__social ul li a {
  transition: 0.2s all;
}

.post-card__img .post-card__social ul li span {
  display: inline-block;
  vertical-align: 3px;
  font-family: 'Roboto', 'Montserrat', sans-serif;
  font-weight: 400;
}

.profile-card2{
  width: 33%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 5px rgba(0,0,0,0.5);
  transition: all 150ms ease-in-out;
  background: white;
}

.profile-card2:hover{
  border: 1px solid #242526;
  box-shadow: 0 0 10px 1px;
}

.card-header2{
  align-self: center;
  text-align: center;
  background: #242526;
  color: #FAFAFA;
  padding-bottom: 115px;
  transition: all 150ms ease-in-out;
  width: 100%;
}

.card-header2 h2 {
  padding-top: 20px;
}

.card-header2:hover{
  background: #242526;
}

.image2{
  margin-top: -90px;
  height: 150px;
  width: 150px;
  align-self: center;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
  z-index: 100;
}
.image2:hover{
  border-radius: 10%;
}
.card-info2{
  padding: 1em;
}
.bottom2 h2{
  text-align:center;
}
.card-footer2{
  width: 310px;
  border-top: 1px solid gray;
  align-self: center;
}
.card-footer2 ul{
  display:flex;
  justify-content: flex-start;;
}
.card-footer2 ul li:last-child{
  margin-left: 85px;
}

/*Align cards so normal css an flexbox display above cards*/

.version1, .version2{
  position:relative;
  left: 14rem;
  color: #BDBDBD;
  transition: color 0.4s ease-in-out;
}
/*Misc hover animations for titles*/
.version1:hover, .version2:hover{
  color: #37474F;
  max-height:100px;
}

@media (max-width: 34rem) {
  .container .post-card {
    width: 304px;
    height: 550px;
    margin: 0px;
    position: relative;
  }

  .container .post-card .post-card__content {
    position: absolute;
    top: calc(70px + 40%);
    justify-content: center;
    left: 0;
    width: 100%;
    align-items: center;
  }

  .post-card__info {
    text-align: center;
  }

  .post-card__info h1, .post-card__info p {
    text-align: center;
  }
  
  .post-card__img {
    position: absolute;
    height: 40%;
    width: 100%;
    top:70px;
    left: 0;
    background-position: 0rem;
    border-radius: 0;
  }

}


.feed-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.profile-top-content {
  display: flex;
  align-items: center;
}

.container2 {
  margin: 0 auto;
  padding: 0 5%;
  max-width: 1280px;
}

.iframe {
  height: 650px;
  width: 650px;
}

.followers {
  overflow: scroll;
  height: 260px;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(45px, 1fr));
  grid-gap: 1.25rem 1rem;
  gap: 1.25rem 1rem;
  padding: 1rem 2rem;
}
article {
  transition: var(--transition);
  padding: 0.15rem 0.5rem;
  border-radius: var(--radius);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}
.followerimg {
    height: 100%;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
  }

  .profileinfo {
    text-align: center;
  }

  .cardtitleinfo {
    text-align: center;
  }
.feed {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  flex-grow: 1;
  background-color: black;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  font-family: "Montserrat", "Roboto", sans-serif;
}

.container .post-card {
  background-color: white;
  width: 700px;
  height: auto;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 0.3rem;
  margin: 10px;
}

.container .post-card:first-child {
  margin-top: 0;
}

.post-card__content {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  padding: 25px;
}

.post-card__info h1 {
  display: block;
  margin: 0;
  font-size: 1.1em;
  color: #333;
}

.post-card__info h5 {
  display: block;
  margin-top: 0.7rem;
  font-weight: 100;
}

.post-card__info h5 span {
  color: #333;
  font-weight: 300;
}

.post-card__info a {
  color: #f44242;
  text-decoration: none;
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.7em;
}

.post-card__info a:hover {
  text-decoration: underline;
}

.post-card__img {
  width: 40%;
  background-image: url(/static/media/A-Team.2907194a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  transition: all 1s;
}

.post-card__img .post-card__social ul {
  list-style-type: none;
  margin: 0;
  padding: 1rem 2rem;
  width: 100%;
  text-align: right;
  color: white;
}

.post-card__img .post-card__social ul li {
  display: inline-block;
  text-shadow: 0 1px rgba(0, 0, 0, 0.5);
}

.post-card__img .post-card__social ul li:first-child {
  margin-right: 2rem;
}

.post-card__img .post-card__social ul li i {
  margin-right: 0.6rem;
  transition: 0.2s all;
}

.post-card__img .post-card__social ul li a {
  transition: 0.2s all;
}

.post-card__img .post-card__social ul li span {
  display: inline-block;
  vertical-align: 3px;
  font-family: "Roboto", "Montserrat", sans-serif;
  font-weight: 400;
}

.profile-card2 {
  width: 320px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  transition: all 150ms ease-in-out;
  background: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.profile-card2:hover {
  border: 1px solid #242526;
  box-shadow: 0 0 10px 1px;
}

.card-header2 {
  align-self: center;
  text-align: center;
  background: #242526;
  color: #fafafa;
  padding-bottom: 115px;
  transition: all 150ms ease-in-out;
}

.card-header2 h1 {
  padding-top: 20px;
}

.card-header2:hover {
  background: #242526;
}

.image2 {
  margin-top: -90px;
  height: 150px;
  width: 150px;
  align-self: center;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
  z-index: 100;
}
.image2:hover {
  border-radius: 10%;
}
.card-info2 {
  padding: 1em;
}
.bottom2 h2 {
  text-align: center;
}
.card-footer2 {
  width: 310px;
  border-top: 1px solid gray;
  align-self: center;
}
.card-footer2 ul {
  display: flex;
  justify-content: flex-start;
}
.card-footer2 ul li:last-child {
  margin-left: 85px;
}

/*Align cards so normal css an flexbox display above cards*/

.version1,
.version2 {
  position: relative;
  left: 14rem;
  color: #bdbdbd;
  transition: color 0.4s ease-in-out;
}
/*Misc hover animations for titles*/
.version1:hover,
.version2:hover {
  color: #37474f;
  max-height: 100px;
}

@media (max-width: 34rem) {
  .container .post-card {
    width: 304px;
    height: 550px;
    margin: 0px;
    position: relative;
  }

  .container .post-card .post-card__content {
    position: absolute;
    top: calc(70px + 40%);
    justify-content: center;
    left: 0;
    width: 100%;
    align-items: center;
  }

  .post-card__info {
    text-align: center;
  }

  .post-card__info h1,
  .post-card__info p {
    text-align: center;
  }

  .post-card__img {
    position: absolute;
    height: 40%;
    width: 100%;
    top: 70px;
    left: 0;
    background-position: 0rem;
    border-radius: 0;
  }
}

.feed-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.post-card12 {
  background-color: white;
  width: 1000px;
  height: 1000px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 0.3rem;
  margin: 10px;
}

.post-card__info2 {
  width: 100%;
}

body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Overpass", sans-serif;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border-radius: 6px;
}
input {
  flex: 1 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: rgb(22, 180, 224);
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: #0052cc;
  cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

