.feed {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  flex-grow: 1;
  background-color: black;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@import "https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700";
@import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,700";

body {
  font-family: "Montserrat", "Roboto", sans-serif;
}

.container .post-card {
  background-color: white;
  width: 700px;
  height: auto;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 0.3rem;
  margin: 10px;
}

.container .post-card:first-child {
  margin-top: 0;
}

.post-card__content {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  padding: 25px;
}

.post-card__info h1 {
  display: block;
  margin: 0;
  font-size: 1.1em;
  color: #333;
}

.post-card__info h5 {
  display: block;
  margin-top: 0.7rem;
  font-weight: 100;
}

.post-card__info h5 span {
  color: #333;
  font-weight: 300;
}

.post-card__info a {
  color: #f44242;
  text-decoration: none;
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.7em;
}

.post-card__info a:hover {
  text-decoration: underline;
}

.post-card__img {
  width: 40%;
  background-image: url("../../images/A-Team.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  transition: all 1s;
}

.post-card__img .post-card__social ul {
  list-style-type: none;
  margin: 0;
  padding: 1rem 2rem;
  width: 100%;
  text-align: right;
  color: white;
}

.post-card__img .post-card__social ul li {
  display: inline-block;
  text-shadow: 0 1px rgba(0, 0, 0, 0.5);
}

.post-card__img .post-card__social ul li:first-child {
  margin-right: 2rem;
}

.post-card__img .post-card__social ul li i {
  margin-right: 0.6rem;
  transition: 0.2s all;
}

.post-card__img .post-card__social ul li a {
  transition: 0.2s all;
}

.post-card__img .post-card__social ul li span {
  display: inline-block;
  vertical-align: 3px;
  font-family: "Roboto", "Montserrat", sans-serif;
  font-weight: 400;
}

.profile-card2 {
  width: 320px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  transition: all 150ms ease-in-out;
  background: white;
  height: fit-content;
}

.profile-card2:hover {
  border: 1px solid #242526;
  box-shadow: 0 0 10px 1px;
}

.card-header2 {
  align-self: center;
  text-align: center;
  background: #242526;
  color: #fafafa;
  padding-bottom: 115px;
  transition: all 150ms ease-in-out;
}

.card-header2 h1 {
  padding-top: 20px;
}

.card-header2:hover {
  background: #242526;
}

.image2 {
  margin-top: -90px;
  height: 150px;
  width: 150px;
  align-self: center;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
  z-index: 100;
}
.image2:hover {
  border-radius: 10%;
}
.card-info2 {
  padding: 1em;
}
.bottom2 h2 {
  text-align: center;
}
.card-footer2 {
  width: 310px;
  border-top: 1px solid gray;
  align-self: center;
}
.card-footer2 ul {
  display: flex;
  justify-content: flex-start;
}
.card-footer2 ul li:last-child {
  margin-left: 85px;
}

/*Align cards so normal css an flexbox display above cards*/

.version1,
.version2 {
  position: relative;
  left: 14rem;
  color: #bdbdbd;
  transition: color 0.4s ease-in-out;
}
/*Misc hover animations for titles*/
.version1:hover,
.version2:hover {
  color: #37474f;
  max-height: 100px;
}

@media (max-width: 34rem) {
  .container .post-card {
    width: 304px;
    height: 550px;
    margin: 0px;
    position: relative;
  }

  .container .post-card .post-card__content {
    position: absolute;
    top: calc(70px + 40%);
    justify-content: center;
    left: 0;
    width: 100%;
    align-items: center;
  }

  .post-card__info {
    text-align: center;
  }

  .post-card__info h1,
  .post-card__info p {
    text-align: center;
  }

  .post-card__img {
    position: absolute;
    height: 40%;
    width: 100%;
    top: 70px;
    left: 0;
    background-position: 0rem;
    border-radius: 0;
  }
}

.feed-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.post-card12 {
  background-color: white;
  width: 1000px;
  height: 1000px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 0.3rem;
  margin: 10px;
}

.post-card__info2 {
  width: 100%;
}
