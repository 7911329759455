.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(https://i.imgur.com/iWUM6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container {
  width: 29%;
}
