.profile-top-content {
  display: flex;
  align-items: center;
}

.container2 {
  margin: 0 auto;
  padding: 0 5%;
  max-width: 1280px;
}

.iframe {
  height: 650px;
  width: 650px;
}

.followers {
  overflow: scroll;
  height: 260px;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(45px, 1fr));
  gap: 1.25rem 1rem;
  padding: 1rem 2rem;
}
article {
  transition: var(--transition);
  padding: 0.15rem 0.5rem;
  border-radius: var(--radius);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 1rem;
}
.followerimg {
    height: 100%;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
  }

  .profileinfo {
    text-align: center;
  }

  .cardtitleinfo {
    text-align: center;
  }